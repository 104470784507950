import { createEditorialRichTextProjection } from './createEditorialRichTextProjection'
import { DEFAULT_LOCALE_ID } from '@portfolio/configs'
import { createBaseImageProjection } from './createBaseImageProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { createMediaProjection } from './createMediaProjection'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSEOProjection } from './createSEOProjection'
import { createRichTextProjection } from './createRichTextProjection'
import { createTagsProjection } from './createTagsProjection'

export const createArticlePageProjection = (localeId: string) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)
  const EDITORIAL_RICH_TEXT_PROJECTION =
    createEditorialRichTextProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const TAGS_PROJECTION = createTagsProjection(localeId)

  return groq`{
      "type":_type,
      "showFooter": true,
      "headerStyle":"transparent-dark",
      "footerMobileButtonVisible":false,
      "addBottomMargin":true,
      "seo": seo${SEO_PROJECTION},
      "headerSection": {
        "_type": "sectionArticleDetails",
        "_key": "sectionArticleDetails" + _id,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'introduction',
          localeId,
        )}[]${EDITORIAL_RICH_TEXT_PROJECTION},
        tags[]->${TAGS_PROJECTION},
       },
      "sidebarSection":{
        "_type": "sectionArticleContents",
        "_key": "sectionArticleContents" + _id,
        "contents": pageBuilder[_type=="sectionArticleChapter"]{
          "chapterTitle":coalesce(title.${localeId}, title.${DEFAULT_LOCALE_ID}),
         },
       },

      "pageBuilder": [
        {
          "_type": "sectionSingleMediaWithDescription",
          "_key": "sectionSingleMediaWithDescription" + _id,
          ${coalesceLocaleField('imageDescription', localeId)},
          "disableTopMargin":true,
          "mediaField":{
            "_type":"simpleMedia",
            "media":image${MEDIA_PROJECTION},
          },
          "mode":"with-margins"
        },
        {
          "_type": "sectionArticleAuthor",
          "_key": "sectionArticleAuthor" + _id,
          authors[]->{
            name,
            avatar${BASE_IMAGE_PROJECTION},
            ${coalesceLocaleField(
              'description',
              localeId,
            )}[]${RICH_TEXT_PROJECTION},
            ${coalesceLocaleField('position', localeId)},
            ${coalesceLocaleField('linkedin', localeId)},
          },

        },
        ...pageBuilder[]${PAGE_BUILDER_PROJECTION},
       ],
      "relatedArticles": {
          "_type": "sectionArticleRelated",
          "_key": "sectionArticleRelated" + _id,
          relatedArticles[]->{
            thumbnail${MEDIA_PROJECTION},
            ${coalesceLocaleField('title', localeId)},
            "path":path.current,
            tags[]->${TAGS_PROJECTION},
          },
        }
  }`
}
