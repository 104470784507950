import { styled } from '@portfolio/styles'

const Tags = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  overflowY: 'hidden',
  overflowX: 'auto',
  width: '100vw',
  scrollSnapType: 'x mandatory',
  gap: '$12',
  padding: '0 $20 $72',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '@md': {
    width: 'fit-content',
    alignItems: 'center',
    margin: '0 auto',
  },
})

const Tag = styled('button', {
  projectFont: 'ui02',
  background: 'none',
  color: '$black',
  borderRadius: '$rMax',
  border: '1px solid $black',
  display: 'inline-flex',
  padding: '$8 $16',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  transition: '0.2s color, 0.2s background ',
  '@lg': {
    '&:hover': {
      color: '$white',
      background: '$black',
    },
  },
  '&:active': {
    color: '$white',
    background: '$black_08',
  },
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
    color: '$white',
    background: '$black',
  },

  variants: {
    selected: {
      true: {
        color: '$white',
        background: '$black',
      },
      false: {
        background: 'none',
        color: '$black',
      },
    },
  },
})

type TagsGroupProps = {
  tags: {
    title: string
    value: string
  }[]
  selectedTag: string | null
  setSelectedTag: (value: string | null) => void
}

export const TagsGroup = ({
  tags,
  selectedTag,
  setSelectedTag,
}: TagsGroupProps) => {
  return (
    <Tags>
      {tags.map((tag) => (
        <Tag
          selected={tag.value === selectedTag}
          onClick={() =>
            tag.value === selectedTag
              ? setSelectedTag(null)
              : setSelectedTag(tag.value)
          }
        >
          {tag.title}
        </Tag>
      ))}
    </Tags>
  )
}
