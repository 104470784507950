import { LocaleId } from '@portfolio/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createTagsProjection = (localeId: LocaleId) => {
  return groq`
    {
        ${coalesceLocaleField('title', localeId)},
        value
    }`
}
