import { styled } from '@portfolio/styles'

const ChipItem = styled('div', {
  projectFont: 'ui04',
  background: 'none',
  color: '$black',
  borderRadius: '$rMax',
  border: '1px solid $black',
  display: 'inline-flex',
  padding: '$6 $12',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  lineHeight: '1.4',
  width: 'fit-content',
})

type ChipProps = {
  title: string
}

export const Chip = ({ title }: ChipProps) => {
  return <ChipItem>{title}</ChipItem>
}
